import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import md5Hex from 'md5-hex';
import Api from "../Api/Api.json";
import { InstallApk } from '../components/InstallAPK';
import '../styles/global.css';

const LoginPage = ({ onLogin }) => {
  var [userType, setUserType] = useState('');
  var [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate=useNavigate();

  useEffect(()=>{
    if (localStorage.getItem('user_info')!==null){
      //InstallApk();
      //console.log("called apk install check");
      navigate('/landing/tab1')
    }
  },[]);

  const handleSubmit=()=>{
    navigate('/Landing')
  }

  //call login check api 
  const handleLogin = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log('in handle login')
    // Perform validation here if needed
    if (username.trim() !== '' && password.trim() !== '') {
      console.log(username.substring(0,1))
      if(username.substring(0,1)==='0'){
        console.log(username.substring(1));
        setUsername(username.substring(1));
        username=username.substring(1);
      }
      console.log(username)
      const headers = { headers: { "Content-Type": "application/json" } }
      //url and task different for different users
      const url = Api.TELEDOC_PROVIDER_API;
      const requestData = {
        task: 'authenticateProvider',
        phone: username,
        password: md5Hex(password)
      };
      
      axios.post(url,requestData,headers)
      .then(response=>{
        console.log(response.data)
        if (response.data.length > 0){
      //onLogin(username);
      //navigate different for different users
      navigate('/landing/dashboard')
      console.log(response.data[0])
      localStorage.setItem('user_info', JSON.stringify(response.data[0]));
        }
      })
      .catch(error=>{
        console.log(error)
      });
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

      <form onSubmit={handleLogin}>
      <h2>Login to Semion Health Pal:</h2>
        <div>
          <label htmlFor="username">Userid(phone/semion id):</label>
          <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div>
          <label htmlFor="password">Password: </label>
          <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit">Login</button>
        <button onClick={() =>navigate('/registration')}>Don't have an account? Register</button>
      </form>
    </div>
  );
};

export default LoginPage;