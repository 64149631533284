import React, { useState,useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from 'axios';
import Api from "../Api/Api.json";
import BottomTab from './BottomTab';

const BP = () => {
  // API response data
  const data = [
    [169.69, [448, 220, 209, 290, 312, 458, 502, 601, 700]],
    [162.69, [418, 120, 231, 230, 410, 400, 410, 602, 810]],
    [152.69, [248, 290, 109, 390, 412, 450, 320, 401, 702]],
  ];

  // State to store the selected values for the left and right radio buttons
  const [selectedLeft, setSelectedLeft] = useState(null);
  const [selectedRight, setSelectedRight] = useState(null);
  const [bpList,setBPList]=useState([]);
  const [waveArray,setWaveArray]=useState(null);
  const [patientId,setPatientId]=useState(null);
  const [measurementTime,setMeasurementTime]=useState(null);

  useEffect(()=>{
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    var requestData = {
      task: 'getBPEditList'
    };
    axios.post(url,requestData,headers)
    .then(response=>{
      if (response.data.length > 0){
      setBPList(response.data);   
      }
    })
    .catch(error=>{
      console.log(error)
    });
  },[]);

  const handleItemClick=async(item)=>{
    await setPatientId(item.patient_id)
    await setMeasurementTime(item.measurement_time)
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    var requestData = {
      task: 'getBPArrayForEdit',
      patient_id:patientId,
      measurement_time:measurementTime
    };
    console.log(requestData);
    axios.post(url,requestData,headers)
    .then(response=>{
      if (response.data.length > 0){
      setWaveArray(response.data);   
      }
    })
    .catch(error=>{
      console.log(error)
    });
  };
  // Submit function to handle the selected values
  const handleSubmit = () => {
    console.log("Left selected:", selectedLeft);
    console.log("Right selected:", selectedRight);
    if(selectedLeft<selectedRight){
      alert(`Systolic is lower than diastolic. Please reverse selection.`);
    }

    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    var requestData = {
      task: 'updateManualSemBPValue',
      patient_id: patientId,
      measurement_time: measurementTime,
      systolic: selectedLeft+7,
      diastolic: selectedRight
    };
    axios.post(url,requestData,headers)
    .then(response=>{
      if (response.data.length > 0){
      setWaveArray(response.data);   
      }
    })
    .catch(error=>{
      console.log(error)
    });

  };

  return (
    <div>
      <h1>BP Wave Plot:</h1>
      <ul>
        {bpList.map((item) => (
          <li
            key={item.measurement_time}
            style={{ cursor: 'pointer', marginBottom: '10px', listStyle: 'none' }}
            onClick={() => handleItemClick(item)}
          >
            {item.measurement_time}
          </li>
        ))}
      </ul>
      {/* Submit Button */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button onClick={handleSubmit}>Submit</button>
      </div>

      {/* Main Plot Area */}
      {waveArray!==null && waveArray.map((item, index) => (
        <div key={`plot-container-${index}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
          
          {/* Left Radio Button */}
          <div style={{ marginRight: '20px' }}>
            <label>
              <input
                type="radio"
                name="leftRadio"
                value={item[0]}
                onChange={() => setSelectedLeft(item[0])}
              />
              {item[0]}
            </label>
          </div>

          {/* Chart */}
          <div style={{ width: '60%' }}>
            <Line
              data={{
                labels: Array(item[1].length).fill(""), // Empty labels for x-axis
                datasets: [
                  {
                    label: `${item[0]}`, // Legend label from the first number
                    data: item[1], // The array data
                    borderColor: "rgba(75,192,192,1)",
                    fill: false,
                  },
                ],
              }}
              options={{
                responsive: true,
                scales: {
                  x: {
                    display: false, // Hide x-axis
                  },
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>

          {/* Right Radio Button */}
          <div style={{ marginLeft: '20px' }}>
            <label>
              <input
                type="radio"
                name="rightRadio"
                value={item[0]}
                onChange={() => setSelectedRight(item[0])}
              />
              {item[0]}
            </label>
          </div>
          
        </div>
      ))}


      <BottomTab />
    </div>
  );
};

export default BP;
