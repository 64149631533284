// App.js
import React, { useState,lazy } from 'react';
import {BrowserRouter as Router,Routes,Route,Outlet,Link,useNavigate} from 'react-router-dom'
import BottomTab  from './pages/BottomTab';
import LoginPage from './pages/LoginPage';
import LandingPage from './pages/LandingPage';
import ShowGraph from './pages/ShowGraph';
import Tab1 from './pages/Tab1';
import CompaintsList from './pages/ComplaintsList';
import MedicineList from './pages/MedicineList';
import Logout from './pages/LogOut';
import ChiefComplaints from './pages/ChiefComplaints';
import './styles/bottomtab.css'
import Medicine from './pages/Medicine';
import RecordAudio from './components/RecordAudio';
//import { lazyLoad } from './lazyLoad';
import MedicalDocument from './pages/MedicalDocuments';
import WellnessPages from './pages/WellnessPages';
import ReportsList from './pages/ReportsList';
import ReportPrint from './pages/ReportPrint';
import RegistrationPage from './pages/RegistrationPage';
import Vitals from './pages/Vitals';
import BP from './pages/BP';
import Chat from './pages/Chat';
import Dashboard from './pages/Dashboard';
import PregnancyList from './pages/PregnancyList';
import SemionList from './pages/SemionList';
import ComplaintsList from './pages/ComplaintsList';
import EditSpeechList from './pages/EditSpeechList';
import AnswerChatList from './pages/AnswerChatList';
import CheckIdlePatients from './pages/CheckIdlePatients';
import WellnessData from './pages/WellnessData'; 
import Stetho from './pages/Stetho';
import SemionScreen from './pages/SemionScreen';
import DoctorReviewNotes from './pages/DoctorReviewNotes';
import WellnessDataFineGrained from './pages/WellnessDataFineGrained';
import RPMList from './pages/RPMList';

//https://www.youtube.com/watch?v=JU6sl_yyZqs
//const LoginPage=lazyLoad('./pages/LoginPage.jsx','Login')
//const LandingPage=lazy(()=>import('./pages/LandingPage.jsx'))

const App = () => {

  return (
    <Router>
      <Routes>
      {/*<Route exact path="/" element={<RecordAudio />} />*/}
      <Route exact path="/" element={<LoginPage />} />
      <Route exact path="/registration" element={<RegistrationPage />} />
      <Route path="/landing/*" element={<LandingPage />} />
      
        <Route path="/landing/tab1" element={<ChiefComplaints />} />
        {/*<Route path="/landing/tab2/*" element={<PrescriptionList />} />*/}
        <Route path="/landing/tab2/*" element={<MedicalDocument />} />        
        <Route path="/landing/tab3/*" element={<WellnessPages />} />
        <Route path="/landing/tab4" element={<Logout />} />
        <Route path={`/tab2/:itemName`} element={<Medicine />} />
        <Route path={`/tab4/:itemName`} element={<ComplaintsList />} />
        <Route path={`/tab3/:itemName`} element={<ReportPrint />} />
        <Route path="/landing/tab5" element={<RecordAudio />} />
        <Route path={`/tab2/Vitals`} element={<Vitals />} />       
        <Route path={`/tab2/WellnessData`} element={<WellnessData />} />   
        <Route path={`/tab2/WellnessData/WellnessDataFineGrained`} element={<WellnessDataFineGrained />} />       
        <Route path={`/tab2/ComplaintList`} element={<ComplaintsList />} />                
        <Route path={`/tab2/MedicineList`} element={<MedicineList />} />                
        <Route path={`/tab2/Reports`} element={<ReportsList />} />      
        <Route path={`/tab2/DoctorReviewNotes`} element={<DoctorReviewNotes />} />      
        <Route path={`/tab2/SemionScreen`} element={<SemionScreen />} />
        <Route path={`/tab2/SemionScreen/BP`} element={<BP />} />
        <Route path={`/chat/:patient_id`} element={<Chat />} />
        <Route path={`/landing/dashboard`} element={<Dashboard />} />
        <Route path={`/landing/rpm_list`} element={<RPMList />} />
        <Route path={`/landing/pregnancy_list`} element={<PregnancyList />} />
        <Route path={`/landing/semion_list`} element={<SemionList />} />
        <Route path={`/landing/edit_speech_list`} element={<EditSpeechList />} />
        <Route path={`/landing/answer_chat_list`} element={<AnswerChatList />} />
        <Route path={`/landing/idle_patient_list`} element={<CheckIdlePatients />} />
        
       {/*<Route path={`/tab3/Sleep`} element={<Sleep />} />*/} 
      </Routes>
    </Router>
  );
};

export default App;

