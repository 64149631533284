import React, { useState,useEffect, useRef  } from 'react';
import { Link} from 'react-router-dom';
import BottomTab from './BottomTab';

const items = ['ComplaintList','MedicineList', 'Reports','DoctorReviewNotes','WellnessData','Vitals', 'HealthLog','Chat','SemionScreen'];

const MedicalDocument = () => {
  //var patient_id=useRef(null);
  const [patient_id, setPatientId] = useState(null);
  useEffect(()=>{

    if (localStorage.getItem('user_info')!==null){
      //navigate('/Landing/tab1')
      //patient_id.current=JSON.parse(localStorage.getItem('patient_id'));
      setPatientId(JSON.parse(localStorage.getItem('patient_id')))
      console.log(patient_id);
    }
  },[]);

    return (
        <div>
          <h1>List of Items</h1>
          <ul>
          <h4> Medical: </h4>
              <li key='1'><Link to={`/tab2/${items[0]}`}>{items[0]}</Link></li>
              <li key='2'><Link to={`/tab2/${items[1]}`}>{items[1]}</Link></li>
              <li key='3'><Link to={`/tab2/${items[2]}`}>{items[2]}</Link></li>
              <li key='4'><Link to={`/tab2/${items[3]}`}>{items[3]}</Link></li>

          <h4> Wellness: </h4>
              <li key='5'><Link to={`/tab2/${items[4]}`}>{items[4]}</Link></li>
              <li key='6'><Link to={`/tab2/${items[5]}`}>{items[5]}</Link></li>
              <li key='7'><Link to={`/landing/tab1`}>{items[6]}</Link></li>
              <li key='8'><Link to={`/chat/${patient_id}`}>{items[7]}</Link></li>
              <li key='9'><Link to={`/tab2/${items[8]}`}>{items[8]}</Link></li>
          </ul>
          <BottomTab />
        </div>
      );
    };


export default MedicalDocument;